<template>
  <div id="app">
    <!-- Aquí puedes agregar una barra de navegación si lo deseas -->
    <nav>
      <router-link to="/">Home</router-link>
      <!-- Agrega más router-links según tus rutas -->
    </nav>
    
    <!-- Este componente renderizará la ruta activa -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>