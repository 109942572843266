<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="19.845" 
    height="18.115" 
    viewBox="0 0 19.845 18.115"
    class="hover:fill-white transition-colors"
  >
    <path 
      d="M20.87,4.25a1.8,1.8,0,0,0-2.407-2.037L2.212,8.491A1.8,1.8,0,0,0,2.107,11.8l3.539,1.633,1.683,5.892a.9.9,0,0,0,1.5.388l2.593-2.593,3.633,2.724a1.8,1.8,0,0,0,2.838-1.076ZM2.86,10.167,19.11,3.889,16.132,18.406l-4.256-3.192A.9.9,0,0,0,10.7,15.3L9.591,16.408l.334-1.835,6.539-6.539a.9.9,0,0,0-1.081-1.415l-9.031,5.16Zm4.594,3.052L8,15.126l.209-1.15a.9.9,0,0,1,.249-.474l1.994-1.994Z" 
      transform="translate(-1.063 -2.089)" 
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>