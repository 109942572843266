<template>
  <div class="container mx-auto p-4">
    <div class="mb-4">
      <div class="flex gap-4 border-b border-gray-700">
        <span 
          @click="activeTab = 'last24'"
          role="button"  
          class="flex items-center gap-2 px-4 py-2 text-white"
          :class="[activeTab === 'last24' ? 'active-btn' : 'disabled-btn']"
          >
          <ClockIcon class="w-6 h-6" />
          Last Transactions
          <MiniArrowDownIcon class="w-6 h-6" />
        </span>
        <span
          @click="activeTab = 'history'"
          role="button"
          class="flex items-center gap-2 px-4 py-2 rounded-full transition-colors text-white px-5 py-2"
          :class="[activeTab === 'history' ? 'active-btn' : 'disabled-btn']"
          >
          <ArrowUpIcon class="w-6 h-6" />
          History
        </span>
      </div>
    </div>

    <table
      class="min-w-full bg-[#0F1015] text-white overflow-hidden crypto-table rounded rounded-lg border-2 border-white">
      <thead>
        <tr class="min-w-full bg-[#0F1015] text-white">
          <th class="px-4 py-2 text-left text-white fs-4">#</th>
          <th class="px-4 py-2 text-left text-white fs-4">COIN</th>
          <th class="px-4 py-2 text-left text-white fs-4">PRICE</th>
          <th class="px-4 py-2 text-left text-white fs-4">VOLUMEN</th>
          <th class="px-4 py-2 text-left text-white fs-4">MARKETCAP</th>
          <th class="px-4 py-2 text-left text-white fs-4">NETWORK</th>
          <th class="px-4 py-2 text-left text-white fs-4">CONTRACT</th>
          <th class="px-4 py-2 text-left text-white fs-4">LINKS</th>
          <th class="px-4 py-2 text-left text-white fs-4">Last Transaction</th>
          <th class="px-4 py-2 text-left text-white fs-4">PROFITS</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(crypto, index) in filteredCryptos" :key="index"
          class="hover:bg-[#1B1D29] transition-colors text-white">
          <td class="px-6 py-4">{{ index + 1 }}</td>
          <td class="px-6 py-4 flex items-center gap-3">
            <img :src="crypto.logoUrl" :alt="crypto.name" class="w-6 h-6 mr-2">
            {{ crypto.symbol }}
          </td>
          <td class="px-4 py-2">${{ Number(crypto.priceUsd).toFixed(5) }}</td>
          <td class="px-4 py-2">${{ Number(crypto.volume).toLocaleString() }}</td>
          <td class="px-4 py-2">${{ Number(crypto.marketCap).toLocaleString() }}</td>
          <td class="px-4 py-2 uppercase">{{ crypto.chainId }}</td>
          <td class="px-4 py-2">
            <div class="flex items-center">
              <span class="text-xs truncate">{{ crypto.tokenAddress }}</span>
              <span role="button" @click="copyToClipboard(crypto.tokenAddress)"
                class="ml-2 text-gray-500 hover:text-gray-400">
                <CopyIcon />
              </span>
            </div>
          </td>
          <td class="px-4 py-2">
            <div v-if="crypto.links" class="flex space-x-2">
              <a v-for="link in crypto.links" :key="link.url" :href="link.url" target="_blank"
                class="text-gray-500 hover:text-gray-400">
                <XIcon v-if="link.type === 'twitter'"></XIcon>
                <TelegramIcon v-else-if="link.type === 'telegram'"></TelegramIcon>
                <LinkIcon v-else></LinkIcon>
              </a>
            </div>
            <span v-else class="text-gray-500">Not available</span>
          </td>
          <td class="px-4 py-2">
            <a v-if="crypto.randomTransaction" :href="getTransactionUrl(crypto.randomTransaction)" target="_blank"
              class="purple hover:underline">
              {{ crypto.randomTransaction?.hash?.substring(0, 8) }}...
            </a>
            <span v-else class="text-gray-500">Not available</span>
          </td>
          <td class="px-4 py-2">
            ${{ calculateProfits(crypto) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import TelegramIcon from "@/assets/icons/TelegramIcon.vue"
import XIcon from '@/assets/icons/XIcon.vue'
import LinkIcon from '@/assets/icons/LinkIcon.vue'
import ArrowUpIcon from '@/assets/icons/ArrowUpIcon.vue';
import ClockIcon from '@/assets/icons/ClockIcon.vue';
import CopyIcon from '@/assets/icons/CopyIcon.vue';
import MiniArrowDownIcon from '@/assets/icons/MiniArrowDownIcon.vue';
import axios from 'axios'

const activeTab = ref('last24')
const cryptosData = ref([])

// Cache to store transactions
const transactionCache = new Map()
const CACHE_DURATION = 5 * 60 * 1000 // 5 minutes

// Array of Helius API keys (you can add more)
const HELIUS_API_KEYS = [
  '4b5b6041-33ce-47c0-b8e3-b3b6aa53c250',
  '8ca5c662-c8e5-495e-9679-0eb01f937990'
]

let currentKeyIndex = 0

// Function to get the next API key
const getNextApiKey = () => {
  currentKeyIndex = (currentKeyIndex + 1) % HELIUS_API_KEYS.length
  return HELIUS_API_KEYS[currentKeyIndex]
}

// Function to wait with exponential backoff
const wait = (attempts) => {
  const baseDelay = 1000 // 1 second
  const maxDelay = 10000 // 10 seconds
  const delay = Math.min(baseDelay * Math.pow(2, attempts), maxDelay)
  return new Promise(resolve => setTimeout(resolve, delay))
}

const ensureMinimumNetworks = (cryptos, count = 10, minEthBsc = 3) => {
  // Separate coins by network
  const ethBsc = cryptos.filter(c => c.chainId === 'ethereum' || c.chainId === 'bnb' || c.chainId === 'base')
  const others = cryptos.filter(c => c.chainId !== 'ethereum' && c.chainId !== 'bnb' && c.chainId !== 'base')

  // Ensure minimum ETH/BSC/BASE
  const selectedEthBsc = ethBsc.slice(0, Math.max(minEthBsc, Math.min(ethBsc.length, count)))

  // Fill the rest with other networks
  const remainingCount = count - selectedEthBsc.length
  const selectedOthers = others.slice(0, remainingCount)

  // Combine and reorder by price if needed
  return [...selectedEthBsc, ...selectedOthers]
}

const filteredCryptos = computed(() => {
  if (activeTab.value === 'last24') {
    // Filter coins with positive gains
    const positiveGains = cryptosData.value
      .filter(crypto => crypto.priceChange > 0)
      .sort((a, b) => b.priceChange - a.priceChange)

    return ensureMinimumNetworks(positiveGains, 10, 3)
  } else {
    // For history tab
    return ensureMinimumNetworks(cryptosData.value, 10, 3)
  }
})

const getRandomTransaction = async (contract, network) => {
  try {
    const networkMapping = {
      'ethereum': 'ethereum',
      'bsc': 'bnb',
      'bnb': 'bnb',
      'base': 'base',
      'solana': 'solana'
    }
    const mappedNetwork = networkMapping[network]

    // Check cache
    const cacheKey = `${contract}-${mappedNetwork}`
    const cachedData = transactionCache.get(cacheKey)
    if (cachedData && Date.now() - cachedData.timestamp < CACHE_DURATION) {
      return cachedData.transaction
    }

    if (mappedNetwork === 'solana') {
      let attempts = 0
      const maxAttempts = 3

      while (attempts < maxAttempts) {
        try {
          const apiKey = getNextApiKey()
          const response = await fetch(
            `https://api.helius.xyz/v0/addresses/${contract}/transactions?api-key=${apiKey}&limit=1`
          )

          if (response.status === 429) { // Too Many Requests
            attempts++
            await wait(attempts)
            continue
          }

          const data = await response.json()
          if (data && data.length > 0) {
            const transaction = {
              hash: data[0].signature,
              network: 'solana'
            }

            // Save to cache
            transactionCache.set(cacheKey, {
              transaction,
              timestamp: Date.now()
            })

            return transaction
          }
          break
        } catch (error) {
          attempts++
          if (attempts === maxAttempts) {
            console.error('Error after max attempts:', error)
            return null
          }
          await wait(attempts)
        }
      }
    } else if (mappedNetwork) {
      const apiConfig = {
        'ethereum': {
          url: 'https://api.etherscan.io/api',
          key: '9U17QFCYTAE6GNGS9C8NDVTDT5JV5P9UTJ'
        },
        'bnb': {
          url: 'https://api.bscscan.com/api',
          key: '6F7SGNYVI1GVPM1IHSHJ6BKU79R7M45TZQ'
        },
        'base': {
          url: 'https://api.basescan.org/api',
          key: 'BRH9UIFH9FUUSCJR4V2TPT2GUKVZXZ7NEM'
        }
      }

      const config = apiConfig[mappedNetwork]
      const response = await fetch(
        `${config.url}?module=account&action=tokentx&contractaddress=${contract}&page=1&offset=1&sort=desc&apikey=${config.key}`
      )
      const data = await response.json()
      if (data && data.result && data.result.length > 0) {
        const transaction = {
          hash: data.result[0].hash,
          network: mappedNetwork
        }

        // Save to cache
        transactionCache.set(cacheKey, {
          transaction,
          timestamp: Date.now()
        })

        return transaction
      }
    }
    return null
  } catch (error) {
    console.error('Error:', error)
    return null
  }
}

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text)
    // You could add a success notification here if desired
  } catch (err) {
    console.error('Error copying to clipboard:', err)
  }
}

const calculateProfits = (crypto) => {
  try {
    // Verificar si tenemos todos los valores necesarios y no son 0
    if (!crypto.priceUsd || !crypto.volume || !crypto.marketCap || 
        crypto.priceUsd === 0 || crypto.volume === 0 || crypto.marketCap === 0) {
      // Generar número aleatorio entre 5 y 30
      return (Math.random() * (30 - 5) + 5).toFixed(2)
    }
    
    // First calculation: price * volume
    const firstCalculation = crypto.priceUsd * crypto.volume

    // Generate random number between 100 and 800
    const randomMultiplier = Math.floor(Math.random() * (800 - 100 + 1)) + 100

    // Second calculation: marketCap * random number
    const secondCalculation = crypto.marketCap * randomMultiplier

    // Final calculation: (secondCalculation / firstCalculation) / 1000
    let profits = (secondCalculation / firstCalculation) / 1000
    
    // Cap profits at 42000
    profits = Math.min(profits, 42000)
    
    // Verificar si el resultado es válido
    if (isNaN(profits) || !isFinite(profits) || profits === 0) {
      // Generar número aleatorio entre 5 y 30
      return (Math.random() * (30 - 5) + 5).toFixed(2)
    }
    
    // Return formatted number with 2 decimal places
    return profits.toFixed(2)
  } catch (error) {
    console.error('Error calculating profits:', error)
    // En caso de error, también devolver número aleatorio entre 5 y 30
    return (Math.random() * (30 - 5) + 5).toFixed(2)
  }
}

const getTransactionUrl = (tx) => {
  const networkMapping = {
    'ethereum': 'etherscan.io',
    'bsc': 'bscscan.com',
    'bnb': 'bscscan.com',
    'base': 'basescan.org',
    'solana': 'solscan.io'  // Using Solscan to view transactions
  }

  const domain = networkMapping[tx.network]
  if (domain && tx.hash) {
    if (tx.network === 'solana') {
      return `https://${domain}/tx/${tx.hash}`
    }
    return `https://${domain}/tx/${tx.hash}`
  }
  return '#'
}

const fetchTokenBoosts = async () => {
  try {
    const { data } = await axios.get('https://api.dexscreener.com/token-profiles/latest/v1')
    const filteredTokens = data
    // Get more tokens to ensure sufficient network diversity
    const tokens = filteredTokens.slice(0, 30) // Increased to 30 to have enough data for both tabs

    const processedCryptos = await Promise.all(
      tokens.map(async (coin) => {
        // Get detailed information for each token
        const { data: coinData } = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/${coin.tokenAddress}`)

        const randomTx = await getRandomTransaction(
          coin.tokenAddress,
          coin.chainId
        )

        return {
          logoUrl: coin.icon,
          volume: coinData.pairs[0].volume.h1,
          priceUsd: coinData.pairs[0].priceUsd,
          marketCap: coinData.pairs[0].marketCap,
          liquidity: coinData.pairs[0].liquidity?.usd || 0,
          symbol: coinData.pairs[0].baseToken.symbol,
          name: coinData.pairs[0].baseToken.name,
          chainId: coin.chainId,
          links: coin.links,
          tokenAddress: coinData.pairs[0].baseToken.address,
          randomTransaction: randomTx,
          priceChange: parseFloat(coinData.pairs[0]?.priceChange?.h24) || 0
        }
      })
    )

    cryptosData.value = processedCryptos
  } catch (error) {
    console.error('Error fetching token boosts:', error)
  }
}

let interval
onMounted(() => {
  fetchTokenBoosts()
  interval = setInterval(fetchTokenBoosts, 60000)
})

onUnmounted(() => {
  clearInterval(interval)
})
</script>

<style>
td {
  background-color: #04000A;
}

.purple {
  color: #632AFF;
}

.text-gris {
  color: #BFBFBF
}

.disabled-btn {
  border: 1px solid #707070; background-color: #04000A;
  border-radius: 30px;
  opacity: 1;
}

.active-btn {
  border: 1px solid #04000A; background-color: #04000A;
  background: transparent linear-gradient(259deg, #05AFCA 0%, #3D48C0 44%, #531FBC 100%) 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
}
</style>