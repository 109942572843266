import { createRouter, createWebHistory } from 'vue-router'
import BodyComponent from '@/components/BodyComponent.vue'
import TableCoinsPage from '@/components/TableCoinsPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: BodyComponent
  },
  {
    path: '/landing',
    name: 'Landing',
    component: TableCoinsPage
  }
  // Aquí puedes agregar más rutas según necesites
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router