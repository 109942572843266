<template>
  <header class="rbt-header rbt-header-10 mt-2">
    <div class="rbt-sticky-placeholder"></div>
    <div class="rbt-header-wrapper header-space-betwween header-transparent header-sticky bg-dark-custom padding-responsive">
      <div class="container-fluid">
        <div class="mainbar-row rbt-navigation-start align-items-center">
          <div class="header-left rbt-header-content">
            <div class="header-info">
              <div class="logo">
                <a href="#">
                  <img 
                    src="assets/images/logo/logo.png" 
                    alt="Logo Images" 
                    class="main-logo" 
                    style="width: 120px; margin-left: 3em;">
                </a>
              </div>
            </div>
          </div>
          <!-- Logo móvil centrado -->
          <div class="mobile-centered-logo">
            <a href="#">
              <img 
                src="assets/images/logo/logo.png" 
                alt="Logo Images" 
                class="mobile-logo" 
                style="width: 120px;">
            </a>
          </div>
          <div class="header-right me-5">
            <!-- <button class="menu-toggle me-3 desktop-only" @click="goToCryptoTable">
              <a class="text-white">Crypto Table</a>
            </button> -->
            <button class="menu-toggle me-5 desktop-only" @click="goToLogin">
              <img :src="BtnIngresar" alt="boton ingresar" style="width: 150px;">
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-menu-overlay" :class="{ 'active': menuOpen }" @click="closeMenu"></div>
    <div class="mobile-menu-container" :class="{ 'active': menuOpen }">
      <!-- Contenido del menú móvil -->
    </div>
  </header>
</template>

<script>
import BtnComenzar from "../../public/assets/images/others/btn-1.png";
import BtnIngresar from "../../public/assets/images/others/btn-ingresar.png";
import { ref } from "vue";
import { useRouter } from 'vue-router';

export default {
  name: 'HeaderComponent',
  setup() {
    const router = useRouter();
    const menuOpen = ref(false);

    const toggleMenu = () => {
      menuOpen.value = !menuOpen.value;
    };

    const closeMenu = () => {
      menuOpen.value = false;
    };

    const goToLogin = () => {
      window.location.href = "https://my.blockblastworld.com/login";
    };

    const goToCryptoTable = () => {
      router.push('/landing');
    };

    return {
      menuOpen,
      toggleMenu,
      closeMenu,
      BtnComenzar,
      BtnIngresar,
      goToLogin,
      goToCryptoTable
    };
  }
};
</script>

<style scoped>
.bg-dark-custom {
  background: #000 !important;
  top: 0 !important;
  position: inherit !important;
}

.rbt-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.rbt-header-wrapper {
  padding: 15px 0;
  transition: all 0.3s ease;
}

.mainbar-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  max-height: 50px;
  width: auto;
}

/* Estilos para el logo móvil centrado */
.mobile-centered-logo {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.mobile-logo {
  display: none;
}

.menu-toggle {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 1001;
}

.mobile-menu-container {
  position: fixed;
  top: 0;
  right: -280px;
  width: 280px;
  height: 100vh;
  background-color: #fff;
  transition: right 0.3s ease;
  z-index: 1002;
}

.mobile-menu-overlay.active,
.mobile-menu-container.active {
  display: block;
  right: 0;
}

.padding-responsive {
  padding: 10px 0px;
}

@media (max-width: 1199px) {
  .rbt-header-wrapper {
    padding: 10px 0;
  }
}

@media (max-width: 767px) {
  .header-left,
  .header-right {
    display: none;
  }
  .padding-responsive {
    padding: 30px 0px;
  }
  
  .mobile-centered-logo {
    display: block;
  }
  
  .mobile-logo {
    display: block;
  }
  
  .mainbar-row {
    justify-content: center;
    position: relative;
  }
  
  .desktop-only {
    display: none;
  }
}
</style>