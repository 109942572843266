<template>
  <div
    class="rbt-accordion-area accordion-style-1 rbt-accordion-color-white bg-color-darker rbt-section-gapBottom pt--60 pt_sm--0 overflow-hidden position-relative top-circle-shape-top overlpping-call-to-action">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 offset-lg-2 col-sm-12">
          <div class="section-title text-center pb--60">
            <h2 class="margin-mobile title mb_sm--0 text-center font-coolvetica letter-spacing-1 text-white">
              Tienes alguna pregunta?
            </h2>
          </div>
          <div class="rbt-accordion-style rbt-accordion-02 accordion">
            <div class="accordion" id="accordionExamplea1">
              <div class="accordion-item card" v-for="(faq, index) in faqs" :key="faq.id">
                <h2 class="accordion-header card-header" :id="'heading' + index">
                  <button class="accordion-button font-coolvetica letter-spacing-06 text-white"
                    :class="{ collapsed: !faq.isOpen }" type="button" @click="toggleAccordion(index)"
                    :aria-expanded="faq.isOpen" :aria-controls="'collapse' + index">
                    {{ faq.question }}
                  </button>
                </h2>
                <div :id="'collapse' + index" class="accordion-collapse collapse" :class="{ show: faq.isOpen }"
                  :aria-labelledby="'heading' + index" data-bs-parent="#accordionExamplea1">
                  <div class="accordion-body card-body" style="white-space: pre-line">
                    {{ faq.answer }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const faqs = ref([
  {
    id: 1,
    question: "¿Qué son los nanomercados?",
    answer:
      "Los nanomercados son segmentos de mercado compuestos por activos, como criptomonedas o empresas, con capitalizaciones extremadamente bajas. Estos mercados suelen ser volátiles y menos eficientes, ofreciendo oportunidades de inversión para aquellos dispuestos a asumir mayores riesgos.",
  },
  {
    id: 2,
    question: "¿Qué son las nano caps?",
    answer:
      "Las nano caps son criptomonedas o empresas con una capitalización de mercado muy pequeña. Aunque su tamaño es reducido, pueden ofrecer un alto potencial de crecimiento, especialmente en sus etapas iniciales, aunque conllevan un riesgo considerable debido a su alta volatilidad.",
  },
  {
    id: 3,
    question: "¿Por qué invertir en nano caps puede ser rentable?",
    answer:
      "Invertir en nano caps puede ser rentable debido a su potencial para crecer rápidamente, aprovechando su baja capitalización inicial. Además, su volatilidad puede generar rendimientos significativos en cortos periodos, aunque esto también implica un mayor riesgo.",
  },
  {
    id: 4,
    question: "¿Cuáles son los riesgos asociados con la inversión en nano caps?",
    answer:
      "El principal riesgo es la alta volatilidad, que puede resultar en pérdidas rápidas y significativas. Además, las nano caps suelen tener menor liquidez y menos cobertura de analistas, lo que puede dificultar la toma de decisiones informadas y aumentar la exposición al riesgo.",
  },
  {
    id: 5,
    question: "¿Cómo se pueden aprovechar las ineficiencias del mercado en nanomercados?",
    answer:
      "Las ineficiencias del mercado en nanomercados pueden aprovecharse mediante un análisis exhaustivo y la búsqueda de información de alta calidad. Los inversores informados pueden identificar y capitalizar discrepancias de precios, logrando rendimientos superiores al promedio del mercado.",
  },
  {
    id: 6,
    question: "¿Qué estrategias de inversión son efectivas en nanomercados?",
    answer:
      "En nanomercados, las estrategias basadas en el análisis fundamental y técnico son especialmente efectivas. Utilizar enfoques como el momentum y la reversión a la media puede ser beneficioso. Además, la diversificación es crucial para mitigar el riesgo inherente a estas inversiones.",
  },
  {
    id: 7,
    question: "¿Qué papel juega la información en la inversión en nano caps?",
    answer:
      "La información es fundamental en la inversión en nano caps. Dado que estos mercados suelen estar menos cubiertos por analistas, disponer de información exclusiva y realizar un análisis detallado puede ofrecer una ventaja significativa, permitiendo explotar las ineficiencias del mercado.",
  },
  {
    id: 8,
    question: "¿Cómo impacta la volatilidad en las nano caps?",
    answer:
      "La volatilidad impacta en las nano caps al hacer que sus precios fluctúen de manera considerable en cortos periodos. Aunque esto puede llevar a grandes ganancias, también implica un alto riesgo de pérdidas. Los inversores deben estar preparados para la naturaleza impredecible de estos activos.",
  },
  {
    id: 9,
    question: "¿Qué es la diversificación y por qué es importante en nanomercados?",
    answer:
      "La diversificación es la práctica de distribuir inversiones entre diferentes activos para reducir el riesgo. En nanomercados, es crucial porque ayuda a mitigar el impacto de la volatilidad extrema y las posibles pérdidas de cualquier activo individual, equilibrando la cartera general.",
  },
  {
    id: 10,
    question: "¿Cómo se manejan los reembolsos y los costos adicionales en los envíos?",
    answer:
      "Todos los pagos adicionales notificados por los usuarios que superen los 10 USD serán revisados y verificados por nuestro equipo financiero. El saldo extra superior a 10 USD será abonado a su BackOffice, donde quedará disponible para retiro.",
  },
  {
    id: 11,
    question: "¿Cuánto tiempo tarda en activarse mi ingreso diario después de comprar un paquete?",
    answer:
      "La activación de su ingreso diario tardará entre 24 y 48 horas después de la compra del paquete.",
  },
  {
    id: 12,
    question: "¿Cuál es el monto mínimo de retiro?",
    answer:
      "El monto mínimo de retiro es de 25 USD. ",
  },
  {
    id: 13,
    question: "¿Cuál es el fee de retiro?",
    answer:
      "El fee de retiro es del 5% sobre el valor retirado, más el costo del fee de la red seleccionada para el retiro.",
  },
  {
    id: 14,
    question: "¿Cuánto tarda en procesarse mi retiro?",
    answer:
      "Todos los retiros tienen un tiempo de procesamiento de entre 1 y 48 horas, dependiendo de la congestión de la red y la cantidad de retiros en la lista de espera.",
  },
  {
    id: 15,
    question: "¿Puedo cancelar mi paquete y solicitar un reembolso?",
    answer:
      "No, nuestra empresa no reembolsa el valor de un paquete comprado después de que este se haya activado, debido a los pagos generados en la red una vez activado.",
  },
  {
    id: 16,
    question: "¿Qué puedo hacer si no recibo códigos de confirmación?  ",
    answer:
      "Nuestro equipo de soporte y WhatsApp está disponible para ayudarle a activar su cuenta. Por favor, proporcione su correo electrónico para que podamos proceder con la activación.",
  },
  {
    id: 17,
    question: "¿Problemas de carga del backoffice?",
    answer:
      "Si el backoffice no carga correctamente y la pantalla aparece en blanco, es posible que el problema se deba a la acumulación de datos en la caché de su navegador. Nuestro equipo de tecnología realiza actualizaciones constantes en el backoffice y CRM para implementar mejoras. Por este motivo, le recomendamos limpiar la caché de su navegador siempre que experimente problemas de carga o funcionalidad.\n\nPara borrar la caché, siga estos pasos:\n\nEn un navegador de escritorio: Diríjase al menú de configuración y seleccione la opción para borrar datos de navegación.\nEn un dispositivo móvil: Acceda a la configuración del navegador y limpie los datos de caché almacenados.",
  },
  {
    id: 18,
    question: "¿Cuáles son los Horarios y canales de atención al cliente?",
    answer:
      "Nuestro horario de atención al cliente es de lunes a viernes, de 8:00 a.m. a 6:00 p.m. (hora estándar de América Latina). Puede comunicarse con nosotros a través de los siguientes canales:\n\n Correo electrónico: support@blockblastworld.com\n\n Correo electrónico: support@blockblastworld.com\n\n WhatsApp: +52 5613707810",
  },
  {
    id: 19,
    question: "¿Condiciones de retiro para cuentas especiales LILA",
    answer:
      "Para realizar el retiro del ROI diario de una cuenta especial LILA, se deben cumplir las siguientes condiciones:\n\n •	El retiro está sujeto a una relación de 1:5 entre la inversión inicial y el volumen de referencia.\n\n • Esto significa que si la cuenta fue activada con una inversión de 1,000 USD, será necesario generar un volumen real de referidos, tanto directos como indirectos, equivalente a 5,000 USD antes de poder retirar el ROI diario.\n\n Estas condiciones garantizan el cumplimiento de los requisitos establecidos para las cuentas especiales LILA y su correcta participación en el programa.\n\n Si necesitas más información o aclaraciones, no dudes en contactarnos.",
  },
]);

const toggleAccordion = (index) => {
  faqs.value = faqs.value.map((faq, i) => ({
    ...faq,
    isOpen: i === index ? !faq.isOpen : false
  }));
};
</script>

<style scoped>
.collapse {
  visibility: visible !important;
}

.rbt-accordion-area {
  padding-bottom: 5em;
}

@media (max-width: 768px) {
  .margin-mobile {
    margin-top: 20px;
  }
}
</style>
