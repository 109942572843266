<template>
    <div class="container mx-auto p-4">
      <div class="d-flex">
        <h2 class="fs-1 font-bold text-white text-white mb-4">
          Live Coin Watch
        </h2>
        <div class="video-popup-wrapper ms-3">
          <img class="w-100 h-50 rbt-radius" src="assets/images/splash/rectangel-title.png" alt="Video Images">
        </div>
      </div>
      <div class="bg-[#1B1D29] rounded-lg p-4">
        <div 
          class="livecoinwatch-widget-5" 
          lcw-base="USD" 
          lcw-color-tx="#ffffff"
          lcw-marquee-1="movers" 
          lcw-marquee-2="movers" 
          lcw-marquee-items="10"
        ></div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { onMounted, onUnmounted } from 'vue';
  
  onMounted(() => {
    const script = document.createElement('script');
    script.src = 'https://www.livecoinwatch.com/static/lcw-widget.js';
    script.defer = true;
    document.head.appendChild(script);
  });
  
  onUnmounted(() => {
    const script = document.querySelector('script[src="https://www.livecoinwatch.com/static/lcw-widget.js"]');
    if (script) {
      document.head.removeChild(script);
    }
  });
  </script>
  
  <style scoped>
  :deep(.livecoinwatch-widget-5) {
    /* Estilos para el widget dentro del contenedor */
    background: transparent !important;
  }
  
  :deep([class*="lcw-base"]) {
    /* Ajuste del color del texto para mejor contraste */
    color: #ffffff !important;
  }
  
  /* Asegurarse de que los enlaces sean visibles */
  :deep(a) {
    color: #ffffff !important;
    transition: opacity 0.2s;
  }
  
  :deep(a:hover) {
    opacity: 0.8;
  }
  </style>