import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "http://localhost:8000",
  timeout: 5000, // Timeout opcional
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Interceptor para requests
axiosInstance.interceptors.request.use(
  (config) => {
    // Aquí puedes agregar lógica antes de cada request
    // Por ejemplo, agregar un token:
    // const token = localStorage.getItem('token')
    // if (token) {
    //     config.headers.Authorization = `Bearer ${token}`
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor para responses
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Aquí puedes manejar errores globalmente
    return Promise.reject(error);
  }
);

export default axiosInstance;
