<template>
  <div class="container mx-auto p-4">
    <div>
      <h2 class="fs-1 font-bold text-dark text-white">
        Most Winners nano
      </h2>
      <table class="min-w-full bg-[#0F1015] text-white overflow-hidden crypto-table rounded rounded-lg border-2 border-white">
        <thead class="relative">
          <div class="artifitial-border absolute top-0 left-0 w-full h-full rounded-lg">.</div>
          <tr class="min-w-full bg-[#0F1015] text-white">
            <th class="px-6 py-4 w-16 text-white fs-4">N°</th>
            <th class="px-6 py-4 text-left text-white fs-4">COIN</th>
            <th class="px-6 py-4 text-left text-white fs-4">MARKETCAP</th>
            <th class="px-6 py-4 text-left text-white fs-4">CHANGE 24h</th>
            <th class="px-6 py-4 text-left text-white fs-4">LINKS</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(crypto, index) in cryptosData" :key="index"
            class="hover:bg-[#1B1D29] transition-colors text-white">
            <td class="px-6 py-4">#{{ index + 1 }}</td>
            <td class="px-6 py-4 flex items-center gap-3">
              <img :src="crypto.logoUrl" :alt="crypto.name" class="w-8 h-8 rounded-full">
              <span class="font-medium">{{ crypto.symbol }}</span>
            </td>
            <td class="px-6 py-4">${{ crypto.marketCap }}</td>
            <td class="px-6 py-4">% {{ crypto.change24h }}</td>
            <td class="px-6 py-4">
              <div class="flex gap-4">
                <a v-for="link in crypto.links" :key="link.url" :href="link.url" target="_blank"
                  class="text-white hover:text-white transition-colors">
                  <XIcon v-if="link.type === 'twitter'"></XIcon>
                  <TelegramIcon v-else-if="link.type === 'telegram'"></TelegramIcon>
                  <LinkIcon v-else ></LinkIcon>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import axios from 'axios'
import TelegramIcon from "@/assets/icons/TelegramIcon.vue"
import XIcon from '@/assets/icons/XIcon.vue'
import LinkIcon from '@/assets/icons/LinkIcon.vue'
import { defineProps } from 'vue'

const cryptos1 = ref([])
const cryptosData = ref([])

defineProps({
  coins: {
    type: Array,
    required: true,
    default: () => []
  }
})

const getRandomTransaction = async (contract, network) => {
  try {
    const networkMapping = {
      'ethereum': 'ethereum',
      'bsc': 'bnb',
      'bnb': 'bnb'
    }

    const mappedNetwork = networkMapping[network]

    if (mappedNetwork) {
      const apiUrl = mappedNetwork === 'ethereum'
        ? 'https://api.etherscan.io/api'
        : 'https://api.bscscan.com/api'
      const apiKey = mappedNetwork === 'ethereum'
        ? '9U17QFCYTAE6GNGS9C8NDVTDT5JV5P9UTJ'
        : '6F7SGNYVI1GVPM1IHSHJ6BKU79R7M45TZQ'

      const response = await fetch(
        `${apiUrl}?module=account&action=tokentx&contractaddress=${contract}&page=1&offset=1&sort=desc&apikey=${apiKey}`
      )
      const data = await response.json()
      if (data && data.result && data.result.length > 0) {
        return {
          hash: data.result[0].hash,
          network: mappedNetwork
        }
      }
    }
    return null
  } catch (error) {
    console.error('Error:', error)
    return null
  }
}


const fetchTokenBoosts = async () => {
  try {
    const { data } = await axios.get('https://api.dexscreener.com/token-profiles/latest/v1')
    const filteredTokens = data.filter(token => token.chainId !== 'solana')
    const firstFiveTokens = filteredTokens.slice(-5)
    cryptos1.value = firstFiveTokens

    await handleGetCoinsInfo()

    const processedCryptos = await Promise.all(
      cryptos1.value.map(async (coin) => {
        const randomTx = await getRandomTransaction(
          coin.tokenAddress,
          coin.chainId
        )

        return {
          logoUrl: coin.icon,
          volume: coin.coininfo.pairs[0].volume.h1,
          priceUsd: coin.coininfo.pairs[0].priceUsd,
          marketCap: coin.coininfo.pairs[0].marketCap,
          liquidity: coin.coininfo.pairs[0].liquidity?.usd || 0,
          symbol: coin.coininfo.pairs[0].baseToken.symbol,
          name: coin.coininfo.pairs[0].baseToken.name,
          change24h: coin.coininfo.pairs[0]?.priceChange?.h24 || 0,
          chainId: coin.chainId,
          links: coin.links,
          randomTransaction: randomTx
        }
      })
    );


    cryptosData.value = processedCryptos.sort(orderByMarketCap);
  } catch (error) {
    console.error('Error fetching token boosts:', error)
  }
}

const handleGetCoinsInfo = async () => {
  try {
    await Promise.all(
      cryptos1.value.map(async (coin) => {
        const { data } = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/${coin.tokenAddress}`)
        coin.coininfo = data
      })
    )
  } catch (error) {
    console.error('handleGetCoinsInfo', error)
  }
}

const orderByMarketCap = (a, b) => {
  return b.marketCap - a.marketCap;
};

let interval
onMounted(() => {
  fetchTokenBoosts()
  interval = setInterval(fetchTokenBoosts, 60000)
})

onUnmounted(() => {
  clearInterval(interval)
})
</script>
