<template>
  <HeaderComponent /> 
  <BodyLanding/>
  <DisclaimerComponent />
  <FooterLanding />
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
// import axios from 'axios'
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterLanding from '@/components/FooterLanding.vue'
import DisclaimerComponent from './DisclaimerComponent.vue'
import BodyLanding from './BodyLanding.vue';

export default {
  name: 'BodyComponent',
  components: {
    HeaderComponent,
    FooterLanding,
    BodyLanding,
    DisclaimerComponent
  },
  setup() {
    const coins = ref([]);

    onMounted(() => {
    });

    onUnmounted(() => {
     
    });

    return {
      coins
    };
  }
}
</script>